<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.announcement.crud.create }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
              {{ err_msg }}
            </CAlert>
            <CForm @submit.prevent="onProfileSubmit">
              <CRow>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.type }}
                      <required_span/>
                    </label>
                    <v-select id="type" :options="typeOptions" v-model="announcement.type"></v-select>
                    <small class="error" v-if="$v.announcement.$error && !$v.announcement.type.required">{{
                        $lang.announcement.validation.required.type
                      }}</small>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.appType }}
                      <required_span/>
                    </label>
                    <v-select id="appType" :options="appTypeOptions" v-model="announcement.appType"></v-select>
                    <small class="error" v-if="$v.announcement.$error && !$v.announcement.appType.required">{{ $lang.announcement.validation.required.appType }}</small>
                  </div>
                </CCol>
                <CCol md="4">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.android }} </label><br>
                    <input type="checkbox" :placeholder="$lang.announcement.form.android" :value="0"
                           @change="changedIsAndroid" v-model="announcement.isAndroid"/>
                  </div>
                </CCol>
                <CCol md="4">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.ios }} </label><br>
                    <input type="checkbox" :placeholder="$lang.announcement.form.ios" :value="0" @change="changedIsIos"
                           v-model="announcement.isIos"/>
                  </div>
                </CCol>
                <CCol md="4">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.web }} </label><br>
                    <input type="checkbox" :placeholder="$lang.announcement.form.web" :value="0" @change="changedIsWeb"
                           v-model="announcement.isWeb"/>
                  </div>
                </CCol>
                <CCol md="12">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.message }}
                      <required_span/>
                    </label>
                    <textarea :maxlength="maxlength.message" :placeholder="$lang.announcement.form.message"
                              class="form-control" v-model="announcement.message"/>
                    <small class="error" v-if="$v.announcement.$error && !$v.announcement.message.required">{{
                        $lang.announcement.validation.required.message
                      }}</small>
                  </div>
                </CCol>
                <!--                <CCol md="6">-->
                <!--                  <div class="form-group">-->
                <!--                    <label class="form__label">{{$lang.announcement.form.startDate }}<required_span /></label>-->
                <!--                    <input type="date" :placeholder="$lang.announcement.form.startDate" class="form-control" v-model="announcement.startDate"/>-->
                <!--                    <small class="error" v-if="$v.announcement.$error && !$v.announcement.startDate.required">{{ $lang.announcement.validation.required.startDate }}</small>-->
                <!--                  </div>-->
                <!--                </CCol>-->
                <!--                <CCol md="6">-->
                <!--                  <div class="form-group">-->
                <!--                    <label class="form__label">{{$lang.announcement.form.endDate }}<required_span /></label>-->
                <!--                    <input type="date" :placeholder="$lang.announcement.form.endDate" class="form-control" v-model="announcement.endDate"/>-->
                <!--                    <small class="error" v-if="$v.announcement.$error && !$v.announcement.endDate.required">{{ $lang.announcement.validation.required.endDate }}</small>-->
                <!--                  </div>-->
                <!--                </CCol>-->
                <!--                <CCol md="6">-->
                <!--                  <div class="form-group">-->
                <!--                    <label class="form__label">{{$lang.announcement.form.startTime }}<required_span /></label>-->
                <!--                    <input type="time" :placeholder="$lang.announcement.form.startTime" class="form-control" v-model="announcement.startTime"/>-->
                <!--                    <small class="error" v-if="$v.announcement.$error && !$v.announcement.startTime.required">{{ $lang.announcement.validation.required.startTime }}</small>-->
                <!--                  </div>-->
                <!--                </CCol>-->
                <!--                <CCol md="6">-->
                <!--                  <div class="form-group">-->
                <!--                    <label class="form__label">{{$lang.announcement.form.endTime }}<required_span /></label>-->
                <!--                    <input type="time" :placeholder="$lang.announcement.form.endTime" class="form-control" v-model="announcement.endTime"/>-->
                <!--                    <small class="error" v-if="$v.announcement.$error && !$v.announcement.endTime.required">{{ $lang.announcement.validation.required.endTime }}</small>-->
                <!--                  </div>-->
                <!--                </CCol>-->
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.isMaintenance }} </label><br>
                    <input type="checkbox" :placeholder="$lang.announcement.form.isMaintenance" :value="0"
                           @change="changedIsActive" v-model="announcement.isMaintenance"/>
                  </div>
                </CCol>
                <CCol md="3">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.new_feature }} </label><br>
                    <input type="checkbox" :placeholder="$lang.announcement.form.new_feature" :value="0"
                           @change="changedIsNewFeature" v-model="announcement.isNewFeature"/>
                  </div>
                </CCol>
                <CCol md="3">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.announcement.form.is_active }} </label><br>
                    <input type="checkbox" :placeholder="$lang.announcement.form.is_active" :value="0"
                           @change="changedActive" v-model="announcement.isActive"/>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="6" class="text-left">
                  <CButton color="primary" :disabled="submitted" class="px-4" type="submit">
                    {{ $lang.common.general.submit }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {announcement} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import {Maxlength} from "../../store/maxlength";

Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "CreateAnnouncement",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      messageColor: "",
      submitted: false,
      SubmitBtn: "Submit",
      err_msg: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      module: announcement,
      msg: "",
      typeOptions: [
        {
          'id': 'info',
          'label': 'info',
        },
        {
          'id': 'danger',
          'label': 'danger',
        },
        {
          'id': 'warning',
          'label': 'warning',
        },
        {
          'id': 'success',
          'label': 'success',
        },
      ],
      appTypeOptions: [
        {
          'id': 'Company',
          'label': 'Company',
        },
        {
          'id': 'Student',
          'label': 'Student',
        },
        {
          'id': 'College',
          'label': 'College',
        },
      ],
      announcement: {
        type: "",
        message: "",
        isMaintenance: 0,
        isWeb: 0,
        isAndroid: 0,
        isIos: 0,
        isNewFeature: 0,
        isActive: 1,
        appType: "",
      },
      maxlength: {
        message: Maxlength.announcement.message,
      },
    };
  },
  validations: {
    announcement: {
      type: {
        required,
      },
      message: {
        required,
      },
      // startDate: {
      //   required,
      // },
      // startTime: {
      //   required,
      // },
      // endDate: {
      //   required,
      // },
      // endTime: {
      //   required,
      // },
    },
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.announcement.isMaintenance = 1;
      } else {
        self.announcement.isMaintenance = 0;
      }
    },
    changedActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.announcement.isActive = 1;
      } else {
        self.announcement.isActive = 0;
      }
    },
    changedIsWeb(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.announcement.isWeb = 1;
      } else {
        self.announcement.isWeb = 0;
      }
    },
    changedIsNewFeature(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.announcement.isNewFeature = 1;
      } else {
        self.announcement.isNewFeature = 0;
      }
    },
    changedIsAndroid(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.announcement.isAndroid = 1;
      } else {
        self.announcement.isAndroid = 0;
      }
    },
    changedIsIos(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.announcement.isIos = 1;
      } else {
        self.announcement.isIos = 0;
      }
    },
    onProfileSubmit() {
      let self = this;
      this.$v.announcement.$touch();
      if (this.$v.announcement.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.submitted = true; //Disable Button
        const postData = {
          "type": self.announcement.type.label,
          "message": self.announcement.message,
          "isAndroid": self.announcement.isAndroid,
          "isWeb": self.announcement.isWeb,
          "isActive": self.announcement.isActive,
          "isIos": self.announcement.isIos,
          "appType": self.announcement.appType.label,
          "isNewFeature": self.announcement.isNewFeature,
          // "startDate": self.announcement.startDate,
          // "endDate": self.announcement.endDate,
          // "endTime": self.announcement.endTime,
          // "startTime": self.announcement.startTime,
          "isMaintenance": self.announcement.isMaintenance,
        }
        axios.post(this.createUrlWeb(this.module), postData)
            .then((response) => {
              self.submitted = false; //Enable Button
              store.commit("showLoader", false); // Loader Off
              if (response.data.code === 200) {
                self.err_msg = response.data.message;
                localStorage.setItem("notificationType", "success");
                localStorage.setItem("notificationMessage", response.data.message);
                this.$router.push({name: "Announcement"});
                self.dismissCountDown = 10;
                self.messageColor = "success";
              } else {
                self.err_msg = response.data.message;
                self.messageColor = "danger";
                self.dismissCountDown = 10;
              }
            }).catch(function (error) {
          let data;
          if (error.response.data.error) {
            data = error.response.data.error.toString();
          } else {
            data = error.response.data.message;
          }
          self.err_msg = data;
          self.messageColor = "danger";
          self.dismissCountDown = 10;
          self.submitted = false; //Enable Button
        });
      }
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
